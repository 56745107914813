import type { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("@/layouts/DashboardLayout.vue"),
    children: [
      {
        path: "user",
        name: "UserList",
        component: () => import("@/views/user/UserListView.vue"),
        meta: {
          title: "User list",
        },
        children: [
          {
            path: ":userId",
            name: "UserDetails",
            component: () => import("@/views/user/UserDetailsView.vue"),
            props: true,
            meta: {
              title: "{username} | User details",
              bannerTitle: "User list",
            },
          },
          {
            path: "create",
            name: "UserCreate",
            component: () => import("@/views/user/UserCreateView.vue"),
            props: true,
            meta: {
              title: "User create",
              bannerTitle: "User list",
            },
          },
          {
            path: "update/:userId",
            name: "UserEdit",
            component: () => import("@/views/user/UserCreateView.vue"),
            props: true,
            meta: {
              title: "Edit user | {username}",
              bannerTitle: "User list",
            },
          },
        ],
      },
    ],
  },
];

export default routes;
