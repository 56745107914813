import { defineStore } from "pinia";

import { useAxios } from "@/services/axios.service.ts";
import Helper from "@/Helper.ts";

import { useSiteStateStore, useAuthStore } from "@/stores";

import { apiResponse, Survey, SurveyKeyStringType, SurveyListRequestType } from "@@/CommonTypes.ts";
import { UserRoles } from "@@/stores/modules/AuthTypes.ts";
// import surveyDetailsJson from "@/views/survey/survey-details-json.ts";

export const defaultSurvey: Survey = {
  created_at: "",
  form_uid: "",
  categories: [],
  survey_id: "",
  surveyor_id: "",
  surveyor_role: UserRoles.Surveyor,
};

const useSurveyStore = defineStore("survey", {
  state: (): {
    surveyList: Survey[];
    survey: Survey;
    search: string | null;
    startDate: string | null;
    endDate: string | null;
    surveyorId: string | null;
  } => ({
    surveyList: [],
    survey: defaultSurvey,
    search: null,
    startDate: null,
    endDate: null,
    surveyorId: null,
  }),
  getters: {
    getAuthenticationInfo() {
      return true;
    },
    getSurveyList(): Survey[] {
      return this.surveyList;
    },
    getSurvey(): Survey {
      return this.survey;
    },
    getSurveyByAuthRole(): Array<Survey> {
      const authStore = useAuthStore();

      const role =
        authStore.user.attributes["custom:role"] === UserRoles.Admin
          ? [UserRoles.Surveyor, UserRoles.Sales_Representative]
          : [UserRoles.Sales_Representative];
      return this.surveyList.filter((survey) => role.includes(survey.surveyor_role as UserRoles));
    },
    getPaginatedSurveyList(): Survey[] {
      const siteStateStore = useSiteStateStore();

      return this.getSurveyByAuthRole.slice(
        siteStateStore.paginator.first,
        siteStateStore.paginator.first + siteStateStore.paginator.rows,
      );
    },
    getSurveyListKeyBy(): (keyBy?: string) => Record<string, Survey> {
      return (keyBy = "survey_id") => {
        return this.surveyList.reduce((acc, user) => {
          if (!user[keyBy]) return acc;

          acc[user[keyBy]] = user;
          return acc;
        }, {} as Record<string, Survey>);
      };
    },
    getSurveyCategoryQuestionsKeyBy(): SurveyKeyStringType {
      return Helper.getSurveyCategoryQuestionsKeyBy(this.survey ?? defaultSurvey);
    },
    getTotalSurveyListCount(): number {
      return this.surveyList.length;
    },
  },
  //since we rely on `this`, we cannot use an arrow function
  actions: {
    setSurveyList(survey: Survey[]) {
      this.surveyList = survey;
    },
    async getSurveyListAction(
      params: SurveyListRequestType,
    ): Promise<apiResponse<unknown> | undefined> {
      const {
        data: { data, status, code, message },
      } = await useAxios().get(`/surveys`, {
        params,
      });

      this.setSurveyList(data as Survey[]);

      return { data, status, code, message };
    },
    async getSurveyAction(params: {
      survey_id: Survey["survey_id"];
    }): Promise<apiResponse<unknown> | undefined> {
      try {
        const {
          data: { data, status, code, message },
        } = await useAxios().get(`/surveys`, {
          params,
        });

        this.survey = data as Survey;
        // this.survey = surveyDetailsJson.data as Survey;

        return { data, status, code, message };
      } catch (error) {
        console.log("error", error);
      }
    },
    async getDownloadLinkAction(params: { survey_ids: Array<Survey["survey_id"]> }): Promise<
      | apiResponse<{
          url: string;
        }>
      | undefined
    > {
      try {
        const {
          data: { data, status, code, message },
        } = await useAxios().post(`/surveys/download`, params);

        return { data, status, code, message };
      } catch (error) {
        console.log("error", error);
      }
    },
  },
});

export default useSurveyStore;
