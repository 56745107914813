<template>
  <div class="flex my-auto text-primary text-2xl">
    <img
      v-if="showIcon"
      alt="a"
      class="mr-1 border-round"
      src="/survey-logo.png"
      style="height: 2.3rem"
    />
    <strong
      :style="{
        display: forceShowBannerText ? 'block' : '',
      }"
      class="hidden sm:block my-auto"
    >
      <i>Survey</i><span class="font-normal">Admin</span>
    </strong>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

defineProps({
  showIcon: {
    type: Boolean,
    default: true,
  },
});

const router = useRouter();

const forceShowBannerText = computed(() => {
  return router.currentRoute.value.meta?.forceShowBannerText;
});
</script>
