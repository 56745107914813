import { createI18n } from "vue-i18n";
import { messages, availableLocales, defaultLocal } from "@/locales";

const locale = localStorage.getItem("locale") ?? defaultLocal;

/**
 * @description This is the main i18n instance that will be used in the app
 */
export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: locale,
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE ?? "en-US",
  messages: messages.value,
  availableLocales: availableLocales.value,
});
