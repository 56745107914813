<template>
  <section data-layout="auth">
    <main>
      <RouterView/>
    </main>
  </section>
</template>

<script lang="ts" setup>
</script>
