import { defineStore } from "pinia";

import {
  SiteStateState,
  RefreshType,
  SiteStateStoreDefinition,
  PaginatorType,
} from "@@/stores/modules/SiteStateTypes.ts";

const defaultRefreshTimeOut = 43200000; // 12 hours
// const defaultRefreshTimeOut = 5000; // 5 seconds
const defaultPaginator: PaginatorType = {
  first: 0,
  page: 0,
  pageCount: 0,
  rows: 10,
};

const useSiteStateStore: SiteStateStoreDefinition = defineStore("site-state", {
  state: (): SiteStateState => ({
    loading: false,
    refresh: { isRequired: false, timeOut: defaultRefreshTimeOut, start: 0, end: 0 },
    offline: false,
    dateFormat: "dd-MM-yyyy",
    dateTimeFormat: "dd-MM-yyyy HH:mm",
    timeFormat: "hh:mm a",
    undefinedStr: "-",
    titleReplace: {},
    paginator: defaultPaginator,
    routerDirectlyAccessed: false,
  }),
  getters: {
    isLoading(state: SiteStateState): boolean {
      return state.loading;
    },
    isOffline(state: SiteStateState): boolean {
      return state.offline;
    },
    getDateFormat(state: SiteStateState): string {
      return state.dateFormat;
    },
    getDateTimeFormat(state: SiteStateState): string {
      return state.dateTimeFormat;
    },
    getTimeFormat(state: SiteStateState): string {
      return state.timeFormat;
    },
    getUndefinedStr(state: SiteStateState): string {
      return state.undefinedStr;
    },
    getRefreshRequire(state: SiteStateState): RefreshType["isRequired"] {
      return state.refresh.isRequired;
    },
    getRefresh(state: SiteStateState): RefreshType {
      return state.refresh;
    },
    getDefaultImage(): string {
      return import.meta.env.VITE_APP_DEFAULT_IMAGE;
    },
  },
  //since we rely on `this`, we cannot use an arrow function
  actions: {
    setIsLoading(value: boolean) {
      this.loading = value;
    },
    setIsOffline(value: boolean) {
      this.offline = value;
    },
    setDateFormat(value: string) {
      this.dateFormat = value;
    },
    setRefreshRequire(value: number) {
      this.refresh.isRequired = value > this.refresh.end;
    },
    setRefreshTimeout(value: RefreshType["timeOut"] = defaultRefreshTimeOut) {
      this.refresh.timeOut = value;

      this.refresh.start = Date.now();
      this.refresh.end = this.refresh.start + this.refresh.timeOut;
    },
    setPaginator(value: SiteStateState["paginator"]) {
      this.paginator = value;
    },
    resetPaginator() {
      this.paginator = defaultPaginator;
    },
    setRouterDirectlyAccessed(value: SiteStateState["routerDirectlyAccessed"]) {
      this.routerDirectlyAccessed = value;
    },
  },
});

export default useSiteStateStore;
