import { createApp, h } from "vue";
import { createPinia } from "pinia";

import "@/assets/styles/scss/main.scss";

import PrimeVue from "primevue/config";
// import AutoComplete from "primevue/autocomplete";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import Badge from "primevue/badge";
// import BadgeDirective from "primevue/badgedirective";
// import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Breadcrumb from "primevue/breadcrumb";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
// import Chart from 'primevue/chart';
// import CascadeSelect from "primevue/cascadeselect";
// import Carousel from "primevue/carousel";
import Checkbox from "primevue/checkbox";
// import Chip from "primevue/chip";
// import Chips from "primevue/chips";
// import ColorPicker from "primevue/colorpicker";
import Column from "primevue/column";
// import ColumnGroup from "primevue/columngroup";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmationService from "primevue/confirmationservice";
// import ContextMenu from "primevue/contextmenu";
import DataTable from "primevue/datatable";
// import DataView from "primevue/dataview";
// import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
// import DeferredContent from "primevue/deferredcontent";
import Dialog from "primevue/dialog";
import DialogService from "primevue/dialogservice";
import Divider from "primevue/divider";
// import Dock from "primevue/dock";
import Dropdown from "primevue/dropdown";
// import DynamicDialog from "primevue/dynamicdialog";
// import Fieldset from "primevue/fieldset";
import FileUpload from "primevue/fileupload";
// import Galleria from "primevue/galleria";
import Image from "primevue/image";
// import InlineMessage from "primevue/inlinemessage";
// import Inplace from "primevue/inplace";
// import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
// import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
// import Knob from "primevue/knob";
// import Listbox from "primevue/listbox";
// import MegaMenu from "primevue/megamenu";
import Menu from "primevue/menu";
// import Menubar from "primevue/menubar";
import Message from "primevue/message";
// import MultiSelect from "primevue/multiselect";
// import OrderList from "primevue/orderlist";
// import OrganizationChart from "primevue/organizationchart";
// import OverlayPanel from "primevue/overlaypanel";
import Paginator from "primevue/paginator";
// import Panel from "primevue/panel";
// import PanelMenu from "primevue/panelmenu";
import Password from "primevue/password";
// import PickList from "primevue/picklist";
// import ProgressBar from "primevue/progressbar";
// import ProgressSpinner from "primevue/progressspinner";
// import Rating from "primevue/rating";
// import RadioButton from "primevue/radiobutton";
import Ripple from "primevue/ripple";
// import Row from "primevue/row";
// import SelectButton from "primevue/selectbutton";
// import ScrollPanel from "primevue/scrollpanel";
// import ScrollTop from "primevue/scrolltop";
// import Skeleton from "primevue/skeleton";
// import Slider from "primevue/slider";
// import Sidebar from "primevue/sidebar";
// import SpeedDial from "primevue/speeddial";
// import SplitButton from "primevue/splitbutton";
// import Splitter from "primevue/splitter";
// import SplitterPanel from "primevue/splitterpanel";
// import Steps from "primevue/steps";
// import StyleClass from "primevue/styleclass";
import TabMenu from "primevue/tabmenu";
// import TieredMenu from "primevue/tieredmenu";
// import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
// import Toolbar from "primevue/toolbar";
// import TabView from "primevue/tabview";
// import TabPanel from "primevue/tabpanel";
// import Tag from "primevue/tag";
// import Terminal from "primevue/terminal";
// import Timeline from "primevue/timeline";
// import ToggleButton from "primevue/togglebutton";
import Tooltip from "primevue/tooltip";
// import Tree from "primevue/tree";
// import TreeSelect from "primevue/treeselect";
// import TreeTable from "primevue/treetable";
// import TriStateCheckbox from "primevue/tristatecheckbox";
// import VirtualScroller from "primevue/virtualscroller";

import { initAmplify } from "@/services/auth.service.ts";
import i18n from "@/services/i18n.service.ts";

import router from "@/router";

import App from "./App.vue";
import BaseJsonPretty from "@/components/reusables/BaseJsonPretty.vue";

export const app = createApp({
  setup() {
    initAmplify(); //Initiating AWS amplify.
  },
  render: () => h(App),
});

app.use(createPinia());
app.use(router); // Router must be used before any other plugin that relies on router hooks
app.use(i18n);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(DialogService);
app.use(ConfirmationService);

app.directive("tooltip", Tooltip);
// app.directive("badge", BadgeDirective);
app.directive("ripple", Ripple);
// app.directive("styleclass", StyleClass);

app.component("PVAccordion", Accordion);
app.component("PVAccordionTab", AccordionTab);
// app.component("PVAutoComplete", AutoComplete);
app.component("PVAvatar", Avatar);
app.component("PVAvatarGroup", AvatarGroup);
app.component("PVBadge", Badge);
// app.component("PVBlockUI", BlockUI);
app.component("PVBreadcrumb", Breadcrumb);
app.component("PVButton", Button);
app.component("PVCalendar", Calendar);
app.component("PVCard", Card);
// app.component('PVChart', Chart);
// app.component("PVCarousel", Carousel);
// app.component("PVCascadeSelect", CascadeSelect);
app.component("PVCheckbox", Checkbox);
// app.component("PVChip", Chip);
// app.component("PVChips", Chips);
// app.component("PVColorPicker", ColorPicker);
app.component("PVColumn", Column);
// app.component("PVColumnGroup", ColumnGroup);
app.component("PVConfirmDialog", ConfirmDialog);
app.component("PVConfirmPopup", ConfirmPopup);
// app.component("PVContextMenu", ContextMenu);
app.component("PVDataTable", DataTable);
app.component("PVDataView", DataView);
// app.component("PVDataViewLayoutOptions", DataViewLayoutOptions);
// app.component("PVDeferredContent", DeferredContent);
app.component("PVDialog", Dialog);
app.component("PVDivider", Divider);
// app.component("PVDock", Dock);
app.component("PVDropdown", Dropdown);
// app.component("PVDynamicDialog", DynamicDialog);
// app.component("PVFieldset", Fieldset);
app.component("PVFileUpload", FileUpload);
// app.component("PVGalleria", Galleria);
app.component("PVImage", Image);
// app.component("PVInlineMessage", InlineMessage);
// app.component("PVInplace", Inplace);
// app.component("PVInputMask", InputMask);
app.component("PVInputNumber", InputNumber);
// app.component("PVInputSwitch", InputSwitch);
app.component("PVInputText", InputText);
// app.component("PVKnob", Knob);
// app.component("PVListbox", Listbox);
// app.component("PVMegaMenu", MegaMenu);
app.component("PVMenu", Menu);
// app.component("PVMenubar", Menubar);
app.component("PVMessage", Message);
// app.component("PVMultiSelect", MultiSelect);
// app.component("PVOrderList", OrderList);
// app.component("PVOrganizationChart", OrganizationChart);
// app.component("PVOverlayPanel", OverlayPanel);
app.component("PVPaginator", Paginator);
// app.component("PVPanel", Panel);
// app.component("PVPanelMenu", PanelMenu);
app.component("PVPassword", Password);
// app.component("PVPickList", PickList);
// app.component("PVProgressBar", ProgressBar);
// app.component("PVProgressSpinner", ProgressSpinner);
// app.component("PVRadioButton", RadioButton);
// app.component("PVRating", Rating);
// app.component("PVRow", Row);
// app.component("PVSelectButton", SelectButton);
// app.component("PVScrollPanel", ScrollPanel);
// app.component("PVScrollTop", ScrollTop);
// app.component("PVSlider", Slider);
// app.component("PVSidebar", Sidebar);
// app.component("PVSkeleton", Skeleton);
// app.component("PVSpeedDial", SpeedDial);
// app.component("PVSplitButton", SplitButton);
// app.component("PVSplitter", Splitter);
// app.component("PVSplitterPanel", SplitterPanel);
// app.component("PVSteps", Steps);
app.component("PVTabMenu", TabMenu);
// app.component("PVTabView", TabView);
// app.component("PVTabPanel", TabPanel);
// app.component("PVTag", Tag);
// app.component("PVTextarea", Textarea);
// app.component("PVTerminal", Terminal);
// app.component("PVTieredMenu", TieredMenu);
// app.component("PVTimeline", Timeline);
app.component("PVToast", Toast);
// app.component("PVToolbar", Toolbar);
// app.component("PVToggleButton", ToggleButton);
// app.component("PVTree", Tree);
// app.component("PVTreeSelect", TreeSelect);
// app.component("PVTreeTable", TreeTable);
// app.component("PVTriStateCheckbox", TriStateCheckbox);
// app.component("PVVirtualScroller", VirtualScroller);

app.component("BaseJsonPretty", BaseJsonPretty);

app.mount("#app");
