import { User } from "@@/CommonTypes.ts";

export type AuthUserType = {
  username: string;
  id: string;
  attributes: AuthUserAttributesType;
};

export type AuthUserAttributesType = {
  sub: string;
  identities: string;
  email_verified: boolean;
  name?: string;
  full_name?: string;
  phone_number: string;
  phone_number_verified: boolean;
  confirmation_status?: ConfirmationStatus;
  email: string;
  avatar?: string;
  "custom:role"?: UserRoles;
  update_time?: string;
};

export type AuthStateType = {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: AuthUserType;
  linkSent: boolean;
  resetEmail: string;
  resetMobile: string;
  currentUser: User;
  fetchingCurrentUser: boolean;
  toasts: Array<ToastType>;
};

export type ToastType = {
  id?: string;
  severity: "success" | "error" | "warning" | "info";
  title: string;
  message: string;
};

export enum UserRoles {
  Admin = "Admin",
  Sales_Admin = "Sales_Admin",
  Surveyor = "Surveyor",
  Sales_Representative = "Sales_Representative",
}

export enum UserRolesTranslation {
  Admin = "Admin",
  Sales_Admin = "Sales Admin",
  Surveyor = "Surveyor",
  Sales_Representative = "Sales Representative",
}

export enum SalesAdminRoles {
  Sales_Admin = UserRoles.Sales_Admin,
  Sales_Representative = UserRoles.Sales_Representative,
}

export enum AdminRoles {
  Admin = UserRoles.Admin,
  Sales_Admin = UserRoles.Sales_Admin,
}

export enum ConfirmationStatus {
  FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
  CONFIRMED = "CONFIRMED",
}
