import { defineStore } from "pinia";
import { DataTableSortEvent } from "primevue/datatable";

import { useAxios } from "@/services/axios.service.ts";

import { useAuthStore, useSiteStateStore } from "@/stores";

import { apiResponse, User } from "@@/CommonTypes.ts";
import { UserRoles } from "@@/stores/modules/AuthTypes.ts";

const useUserStore = defineStore("user", {
  state: () => ({
    isLoading: false,
    selectedOrganization: null,
    users: [] as User[],
    user: {} as User,
    role: "" as UserRoles,
    search: "",
    sort: {
      field: "full_name" as unknown as DataTableSortEvent["sortField"],
      order: 1 as unknown as DataTableSortEvent["sortOrder"],
    },
  }),
  getters: {
    getUser(): User {
      return this.user;
    },
    getAuthenticationInfo() {
      return true;
    },
    getUsers(): User[] {
      return this.users;
    },
    getUsersKeyBy(): () => Record<string, User> {
      return (keyBy = "id") => {
        return this.users.reduce((acc, user) => {
          if (!user[keyBy]) return acc;

          acc[user[keyBy]] = user;
          return acc;
        }, {} as Record<string, User>);
      };
    },
    getSurveyUsers(): User[] {
      return this.getUsersOrderBy;
    },
    getUsersByAuthRole(): Array<User> {
      const authStore = useAuthStore();

      const role =
        authStore.user.attributes["custom:role"] === UserRoles.Admin
          ? [
              UserRoles.Surveyor,
              UserRoles.Sales_Representative,
              UserRoles.Sales_Admin,
              UserRoles.Admin,
            ]
          : [UserRoles.Sales_Representative, UserRoles.Sales_Admin];
      return this.getUsers.filter((user) => role.includes(user.role as UserRoles));
    },
    getUsersOrderBy(): Array<User> {
      return this.getUsersByAuthRole.sort((a, b) => {
        let _a = this.sort.order === 1 ? a : b;
        let _b = this.sort.order === 1 ? b : a;
        return (_a?.[(this.sort?.field ?? "").toString() as string] ?? _a.email)?.localeCompare(
          _b?.[(this.sort?.field ?? "").toString() as string] ?? _b.email,
        );
      });
    },
    getUsersKeyByRole(): Record<string, Array<User>> {
      return this.getUsersOrderBy.reduce((acc, user) => {
        if (!acc[user["role"]]) {
          acc[user["role"]] = [];
        }

        acc[user["role"]].push(user);
        return acc;
      }, {} as Record<string, Array<User>>);
    },
    getUsersByRole(): Array<User> {
      return this.role ? this.getUsersKeyByRole[this.role] || [] : this.getUsersOrderBy;
    },
    getUsersByGlobalSearch(): Array<User> {
      return this.search
        ? this.getUsersByRole.filter((user) => {
            return (
              user?.full_name?.toLowerCase().includes(this.search.toLowerCase()) ||
              user?.email?.toLowerCase().includes(this.search.toLowerCase()) ||
              user?.phone_number?.toLowerCase().includes(this.search.toLowerCase())
            );
          })
        : this.getUsersByRole;
    },
    getPaginatedUsers(): User[] {
      const siteStateStore = useSiteStateStore();

      return this.getUsersByGlobalSearch.slice(
        siteStateStore.paginator.first,
        siteStateStore.paginator.first + siteStateStore.paginator.rows,
      );
    },
    getTotalUsersCount(): number {
      return this.getUsersByGlobalSearch.length;
    },
  },
  //since we rely on `this`, we cannot use an arrow function
  actions: {
    setUsers(users: User[]) {
      this.users = users;
    },
    async getUsersAction(params) {
      try {
        const {
          data: { data, status, code, message },
        } = await useAxios().get("/users", {
          params,
        });

        this.setUsers(data as User[]);

        return { data, status, code, message };
      } catch (error) {
        console.log("error", error);
      }
    },
    async getAllUsersAction() {
      try {
        const {
          data: { data, status, code, message },
        } = await useAxios().get("/users");

        this.setUsers(data as User[]);

        return { data, status, code, message };
      } catch (error) {
        console.log("error", error);
      }
    },
    async getUserAction(params: { id: User["id"] }): Promise<apiResponse<unknown> | undefined> {
      try {
        const {
          data: { data, status, code, message },
        } = await useAxios().get(`/users`, {
          params,
        });

        this.user = data as User;

        return { data, status, code, message };
      } catch (error) {
        console.log("error", error);
      }
    },
    async createUserAction(params: User): Promise<apiResponse<unknown> | undefined> {
      try {
        const {
          data: { data, status, code, message },
        } = await useAxios().post(`/users/create`, params);

        return { data, status, code, message };
      } catch (error) {
        console.log("error", error);
      }
    },
    async updateUserAction(params: User): Promise<apiResponse<unknown> | undefined> {
      try {
        const {
          data: { data, status, code, message },
        } = await useAxios().put(`/users/update`, params);

        return { data, status, code, message };
      } catch (error) {
        console.log("error", error);
      }
    },
    async deleteUserAction(params: { id: User["id"] }): Promise<apiResponse<unknown> | undefined> {
      try {
        const {
          data: { data, status, code, message },
        } = await useAxios().delete(`/users/delete`, {
          data: JSON.stringify(params),
        });

        return { data, status, code, message };
      } catch (error) {
        console.log("error", error);
      }
    },
    clearUser() {
      this.user = {} as User;
    },
  },
});

export default useUserStore;
