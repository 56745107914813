import { computed, ref } from "vue";
import { format, parse } from "date-fns";
import type { RouteLocationNormalizedLoaded, RouteRecordRaw } from "vue-router";
import type { MenuItem } from "primevue/menuitem";
import { FileUploadSelectEvent } from "primevue/fileupload";

import { error, info, success, warning } from "@/services/toast.service.ts";

import { useAuthStore, useSiteStateStore, useSurveyStore, useUserStore } from "@/stores";

import {
  DebounceType,
  Survey,
  SurveyCategoryQuestionsKeyStringType,
  SurveyKeyStringType,
  SurveyQuestionsOnlyKeyStringType,
  SurveyQuestionType,
  SurveySubQuestionsKeyStringType,
  SurveySingleQuestionType,
  SurveySingleQuestionWithSubType,
  S3ImageBases,
} from "@@/CommonTypes.ts";

const serviceToasts = computed(() => ({
  success,
  info,
  warning,
  error,
}));

const debounceTimeout = ref<ReturnType<typeof setTimeout>>();
const debounceAfter = ref<number>();
const defaultDebounceAfter = 380;

export default class Helper {
  private static _loginUser = ref();
  static get loginUser(): any {
    return this._loginUser.value;
  }

  static set loginUser(value: any) {
    this._loginUser.value = value;
  }

  static getMenu() {
    const menuItems = ref<Array<MenuItem>>([]);
    const vueFiles = {
      ...import.meta.glob("@/theme/*.vue"),
      ...import.meta.glob("@/theme/*/*.vue"),
    };
    const components = ref<{
      [p: string]: RouteRecordRaw["component"];
    }>({});

    Object.entries(vueFiles).forEach(([name, item]) => {
      const splits: Array<string> = (name as unknown as string)?.split("/") ?? [];
      const component: string = splits.at(-1)?.split("Component.vue")?.at(0) as string;
      const componentLowerCase = component?.toLowerCase();
      components.value[componentLowerCase] = item;

      menuItems.value.push({
        label: component.charAt(0).toUpperCase() + component.slice(1),
        icon: "pi pi-fw pi-circle-on",
        to: `/theme/${componentLowerCase}`,
      });
    });

    return { menuItems, vueFiles, components };
  }

  static makeFloatToFixed(value: number | string, toFixed = 2) {
    return parseFloat((value || 0).toString()).toFixed(toFixed);
  }

  static getPageTitle(titles: unknown[], join = " | "): string {
    return [...titles, import.meta.env.VITE_APP_TITLE].filter((item) => item).join(join);
  }

  static setPageTitle(title: string) {
    document.title = title ?? document.title;
  }

  static updatePageTitle(titles: unknown[], join = " | ") {
    this.setPageTitle(this.getPageTitle(titles, join));
  }

  static replacePageTitle(
    to: RouteLocationNormalizedLoaded,
    replaceObj: Record<string | number, string>,
  ) {
    if (Object.keys(replaceObj)?.length) {
      this.setPageTitle(
        this.templateMultiStringReplace(
          replaceObj,
          this.getPageTitle([to?.meta?.title ?? to?.meta?.titleDefault]),
        ),
      );
    }
  }

  static capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static templateMultiStringReplace<T extends string>(
    object: Record<string | number, string>,
    string: T,
  ): T {
    const entries = Object.entries(object);
    entries.forEach(([key, value]) => {
      const find = "{" + key + "}";
      const regExp = new RegExp(find, "g");
      string = string.replace(regExp, value as T) as T;
    });
    return string;
  }

  static getAvatarUrl(name: string) {
    return `https://ui-avatars.com/api/?background=random&name=${name}`;
  }

  static encodeImageFileAsURL(element: FileUploadSelectEvent, callback) {
    const file = element.files[0];
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }

  static replaceWithDefault(event: Event, str?: string) {
    const siteStateStore = useSiteStateStore();

    const target = event.target as HTMLImageElement;
    target.src = str ? this.getAvatarUrl(str) : siteStateStore.getDefaultImage;
  }

  static getTypeWiseProcessedQuestion(question: SurveyQuestionType) {
    const siteStateStore = useSiteStateStore();

    const _question = question.title;
    let _answer = question.answer;

    try {
      switch (question.type) {
        case "text":
          _answer = question.answer;
          break;
        case "email":
          _answer = question.answer;
          break;
        case "date": {
          if (_answer) {
            const dateStr = (question.answer ?? "").toString();
            _answer = format(parse(dateStr, "dd/MM/yyyy", new Date()), siteStateStore.dateFormat);
          }
          break;
        }
        case "time": {
          if (_answer) {
            const dateStr = (question.answer ?? "").toString();
            _answer = format(parse(dateStr, "HH:mm", new Date()), siteStateStore.timeFormat);
          }
          break;
        }
        case "datetime": {
          if (_answer) {
            const dateStr = (question.answer ?? "").toString();
            _answer = format(
              parse(dateStr, "dd/MM/yyyy", new Date()),
              siteStateStore.dateTimeFormat,
            );
          }
          break;
        }
      }
    } catch (err) {
      error("Error", (err ?? "")?.toString());
    }

    return {
      ...question,
      question: _question,
      answer: _answer as string,
    };
  }

  static showToastsIfAny() {
    const authStore = useAuthStore();

    const toasts = [...authStore.toasts];
    authStore.toasts = [];

    toasts.forEach((toast) => {
      serviceToasts.value?.[toast.severity](toast.title, toast.message);
    });
  }

  static getArrayKeyBy = <T>(
    arr: Array<T>,
    key: string | number,
    mergeItemKey?: string | number,
  ): Record<typeof key, T> => {
    return arr.reduce((acc, cur) => {
      if (!acc[cur[key]]) {
        acc[cur[key]] = cur;
      } else if (mergeItemKey) {
        acc[cur[key]][mergeItemKey] = {
          ...(acc[cur[key]]?.[mergeItemKey] ?? {}),
          ...(cur?.[mergeItemKey] ?? {}),
        };
      }
      return acc;
    }, {} as Record<typeof key, T>);
  };

  static getSurveyCategoriesKeyByName = (
    surveyCategoryQuestionsKeyByTitle: SurveyQuestionsOnlyKeyStringType,
  ): SurveyKeyStringType => {
    return {
      ...surveyCategoryQuestionsKeyByTitle,
      categories: Helper.getArrayKeyBy<SurveyCategoryQuestionsKeyStringType>(
        surveyCategoryQuestionsKeyByTitle.categories,
        "name",
        "questions",
      ),
    };
  };

  static getSurveyQuestionsKeyByTitle = (survey: Survey): SurveyQuestionsOnlyKeyStringType => {
    return {
      ...survey,
      categories: !survey.categories
        ? []
        : survey.categories.map((category): SurveyCategoryQuestionsKeyStringType => {
            const _questions = category.questions.map<SurveySingleQuestionWithSubType>(
              (question) => {
                const _subquestions: SurveySubQuestionsKeyStringType =
                  this.getArrayKeyBy<SurveySingleQuestionType>(
                    question.subquestions ?? [],
                    "title",
                  );

                return {
                  ...question,
                  subquestions: JSON.stringify(_subquestions) === "{}" ? undefined : _subquestions,
                };
              },
            );

            const questions = this.getArrayKeyBy<SurveySingleQuestionWithSubType>(
              _questions,
              "title",
            );

            return {
              ...category,
              questions,
            };
          }),
    };
  };

  static getSurveyCategoryQuestionsKeyBy = (survey: Survey): SurveyKeyStringType => {
    return this.getSurveyCategoriesKeyByName(this.getSurveyQuestionsKeyByTitle(survey));
  };

  static getSurveyCategoryQuestion = <T>(survey: Survey, category: string, question: string): T => {
    const surveyKeyBy = this.getSurveyCategoryQuestionsKeyBy(survey);

    const storeDetails = surveyKeyBy.categories[category ?? "Store Details"];

    const _question = storeDetails?.questions?.[question ?? "Shop Name"] ?? {};

    return (_question?.answer ?? "-") as T;
  };

  static getSurveyPhotos = (survey: Survey): Array<string> => {
    const photos =
      this.getSurveyCategoryQuestion<string | Array<string>>(
        survey,
        "Store Details",
        "Shop External Photo",
      ) ?? [];
    return Array.isArray(photos) ? photos : [photos];
  };

  static getSurveyShopName = (survey: Survey): string => {
    return this.getSurveyCategoryQuestion<string>(survey, "Store Details", "Shop Name");
  };

  static debounce = (options: DebounceType) => {
    const search = options.search ?? "";

    debounceAfter.value = options.after ?? defaultDebounceAfter;
    const callback = options.callback ?? (() => ({}));

    clearTimeout(debounceTimeout.value);

    debounceTimeout.value = setTimeout(() => {
      callback({
        search,
      });
    }, debounceAfter.value || defaultDebounceAfter);
  };

  static getS3ImageUrl = (url: string, prefix = S3ImageBases.ASSETS) => {
    prefix = prefix || S3ImageBases.ASSETS;

    return `${import.meta.env.VITE_APP_ASSET_SCHEME}${
      import.meta.env.VITE_APP_ASSET_URL
    }${prefix}${url}`;
  };

  static getMenuQuery = (name: string) => {
    const userStore = useUserStore();
    const surveyStore = useSurveyStore();

    const query: Record<string, string | null> = {};

    switch (name) {
      case "SurveyList":
        query.search = surveyStore.search || null;
        query.start_date = surveyStore.startDate || null;
        query.end_date = surveyStore.endDate || null;
        query.surveyor_id = surveyStore.surveyorId || null;
        break;
      case "UserList":
        query.role = userStore.role || null;
        query.search = userStore.search || null;
        break;
    }

    return query;
  };
}
