import { defineStore } from "pinia";

import {
  changePassword,
  completeNewPasswordChallenge,
  forgotPassword,
  forgotPasswordSubmit,
  getAuthenticatedUser,
  getAuthJWTToken,
  getUser,
  refreshToken,
  signIn,
  signOut,
} from "@/services/auth.service.ts";
import LoaderController from "@/controllers/LoaderController.ts";
import { useAxios } from "@/services/axios.service.ts";
import Helper from "@/Helper.ts";

import { ForgotPassword, NewPasswordChallenge } from "@@/AuthTypes.ts";
import {
  AuthStateType,
  AuthUserType,
  ConfirmationStatus,
  UserRoles,
} from "@@/stores/modules/AuthTypes.ts";
import { User } from "@@/CommonTypes.ts";

export const defaultUser: AuthStateType["user"] = {
  username: "",
  id: "",
  attributes: {
    sub: "",
    identities: "",
    email_verified: false,
    full_name: "",
    name: "",
    phone_number: "",
    phone_number_verified: false,
    confirmation_status: undefined,
    email: "",
    "custom:role": UserRoles.Surveyor,
  },
};

export const defaultCurrentUser: User = {
  id: "",
  full_name: "",
  phone_number: "",
  email: "",
  avatar: "",
  role: UserRoles.Surveyor,
  confirmation_status: ConfirmationStatus.CONFIRMED,
  update_time: "",
};

const useAuthStore = defineStore("auth", {
  state: (): AuthStateType => ({
    isAuthenticated: false,
    user: defaultUser,
    isLoading: false,
    linkSent: false,
    resetEmail: "",
    resetMobile: "",
    currentUser: defaultCurrentUser,
    fetchingCurrentUser: false,
    toasts: [],
  }),
  getters: {
    getUser(): AuthUserType {
      return this.user;
    },
    getUserRole(): UserRoles {
      return this.user?.attributes?.["custom:role"] ?? UserRoles.Surveyor;
    },
    getCurrentUser(): User {
      return this.currentUser;
    },
  },
  //since we rely on `this`, we cannot use an arrow function
  actions: {
    setIsAuthenticated(isAuthenticated: boolean) {
      this.isAuthenticated = isAuthenticated;
    },
    setUsers(user: AuthUserType) {
      this.user = user;
    },
    async setCurrentUserAction(params?: { id: User["id"] }) {
      params = {
        ...(params ?? {}),
        id: this.user?.attributes?.sub ?? "",
      };

      this.fetchingCurrentUser = true;
      const {
        data: { data },
      } = await useAxios().get(`/users`, {
        params,
      });
      this.fetchingCurrentUser = false;

      if (!data) return;

      this.currentUser = data as User;
    },
    async signInAction(email: string, password: string) {
      try {
        const response = await signIn(email, password);

        if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
          return response;
        }

        this.setIsAuthenticated(true);

        return response;
      } catch (error) {
        console.log("error", error);
        throw error;
      }
    },
    async signOutAction() {
      const response = await signOut();

      this.resetAuthState();

      console.log("User signed out");

      return response;
    },
    async getUserAction() {
      this.isLoading = true;
      LoaderController.showLoader();

      const user = await getUser();
      this.setIsAuthenticated(!!user);
      this.setUsers(user ?? defaultUser);

      LoaderController.hideLoader();
      this.isLoading = false;

      return user;
    },
    async getAuthenticatedUserAction() {
      return await getAuthenticatedUser();
    },
    async getAuthJWTTokenAction() {
      return await getAuthJWTToken();
    },
    async refreshTokenAction() {
      return await refreshToken();
    },
    async changePasswordAction(oldPassword: string, newPassword: string) {
      return await changePassword(oldPassword, newPassword);
    },
    async forgotPasswordAction(userName: string) {
      return await forgotPassword(userName);
    },
    async forgotPasswordSubmitAction(payload: ForgotPassword) {
      return await forgotPasswordSubmit(payload);
    },
    async completeNewPasswordChallengeAction(payload: NewPasswordChallenge) {
      return await completeNewPasswordChallenge(payload);
    },
    resetAuthState() {
      this.setIsAuthenticated(false);
      this.setUsers(defaultUser);
      this.currentUser = defaultCurrentUser;
      this.toasts = [];
      Helper.loginUser = null;
    },
  },
});

export default useAuthStore;
