<script setup lang="ts">
// import libraries
import { onBeforeMount, onMounted, watch } from "vue";
import { useRouter } from "vue-router";

// import vue files
import OfflineWarning from "@/components/common/OfflineWarning.vue";

// import services, controllers, helpers, utilities, etc.
import LoaderController from "@/controllers/LoaderController.ts";
import Helper from "@/Helper.ts";

// import states
import { useSiteStateStore } from "@/stores";

// import types

const siteStateStore = useSiteStateStore();
const router = useRouter();

onBeforeMount(() => {
  LoaderController.showLoader();
});

onMounted(() => {
  LoaderController.hideLoader();
});

watch(
  () => siteStateStore.titleReplace,
  (titleReplace) => {
    Helper.replacePageTitle(router.currentRoute.value, titleReplace);
  },
  {
    deep: true,
  },
);
</script>

<template>
  <OfflineWarning />

  <RouterView />

  <PVToast />
  <PVConfirmPopup></PVConfirmPopup>
</template>
