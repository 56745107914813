/**
 * Import dependencies
 */
import { nextTick } from "vue";
import { createRouter, createWebHistory } from "vue-router";

/**
 * Import views
 */
import HomeView from "@/views/HomeView.vue";

/**
 * Import types
 */
import type { RouteRecordRaw } from "vue-router";

/**
 * Import stores
 */
import { useAuthStore, useSiteStateStore } from "@/stores";

/**
 * Import controllers
 */
// import LoaderController from "@/controllers/LoaderController.ts";

/**
 * Import utilities
 */
import Helper from "@/Helper.ts";

/**
 * Import routes
 */
import authRoutes from "@/views/auth/auth-routes.ts";
import dashboardRoutes from "@/views/dashboard/dashboard-routes.ts";
import userRoutes from "@/views/user/user-routes.ts";
import surveyRoutes from "@/views/survey/survey-routes.ts";
import errorRoutes from "@/views/errors/error-routes.ts";
import profileRoutes from "@/views/profile/profile-routes.ts";

/**
 * Define routes
 */
const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  ...authRoutes,
  ...dashboardRoutes,
  ...userRoutes,
  ...surveyRoutes,
  ...profileRoutes,
  ...errorRoutes,
];

/**
 * Create router
 */
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

/**
 * Before each route
 */
router.beforeEach(async (to, from) => {
  // LoaderController.showLoader();
  const siteStateStore = useSiteStateStore();

  siteStateStore.setRouterDirectlyAccessed(false);
  // check if url directly accessed
  if (from.fullPath === "/" && to.fullPath !== from.fullPath) {
    siteStateStore.setRouterDirectlyAccessed(true);
  }

  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    siteStateStore.titleReplace = {};
    Helper.updatePageTitle([to?.meta?.titleDefault ?? to?.meta?.title]);
  }).then();
});

/**
 * After each route
 */
router.afterEach(async (to) => {
  const authStore = useAuthStore();

  // Redirect to login if not authenticated
  if ((!to.meta.isPublic || to.meta.checkAuth) && !authStore.isAuthenticated) {
    if (to.meta.checkAuth) {
      return await router.push({ name: "Home" });
    }

    try {
      await authStore.getUserAction();
      await authStore.setCurrentUserAction();
    } catch (error) {
      await router.push({ name: "Login" });
    }

    if (!authStore.isAuthenticated) {
      await router.push({ name: "Login" });
    }
  }

  // LoaderController.hideLoader();
});

/**
 * Export router
 */
export default router;
