<template>
  <BaseAuthMessageDialog
    v-if="!showView"
    ref="baseAuthMessageDialogRef"
    :show-loading-string="true"
  >
    <template #title>
      <span>
        {{ authMessageTitle }}
      </span>
    </template>
    <template #description>
      <template v-if="router.currentRoute.value.name == `Login`"></template>
      <template v-if="router.currentRoute.value.name == `Logout`">
        <br />
        <small>
          If you are not redirected in {{ redirectAfter_sec }} seconds, <br />please click
          <a v-bind="{ href: state.intended }">here</a> to go back to the home page.
        </small>
      </template>
      <template v-if="router.currentRoute.value.name === `Auth0Redirect`">
        <br />
        <small>
          If you are not redirected in {{ redirectAfter_sec }} seconds, <br />please click
          <RouterLink to="/">here</RouterLink>
          to go back to the home page.
        </small>
      </template>
    </template>
  </BaseAuthMessageDialog>
  <RouterView v-if="showView" />
</template>

<script lang="ts" setup>
import { onMounted, computed, ref } from "vue";
import { useRouter } from "vue-router";

import BaseAuthMessageDialog from "@/components/reusables/BaseAuthMessageDialog.vue";

import { useAuthStore } from "@/stores";

const router = useRouter();
const authStore = useAuthStore();

const state = computed(() => ({
  intended: "/",
}));

const authMessageTitle = ref("Please wait while we check your authentication");
const redirectAfter_sec = ref(5);
const showView = ref(false);

const isAuthenticated = computed(() => authStore.isAuthenticated);

onMounted(async () => {
  console.log("AuthLayer - isAuthenticated.value", isAuthenticated.value);

  if (router.currentRoute.value.name === "LoginAuthAWS") {
    showView.value = true;
    return;
  }

  if (router.currentRoute.value.name === "Login") {
    console.log("AuthLayer - onMounted - Login");
    await authStore.getUserAction();
    console.log("AuthLayer - onMounted - Login - isAuthenticated.value", isAuthenticated.value);

    if (isAuthenticated.value) {
      await router.push({ name: "Dashboard" });
      return;
    } else {
      showView.value = true;
    }

    return;
  }

  if (router.currentRoute.value.name === "Logout") {
    console.log("AuthLayer - onMounted - Logout");
    showView.value = true;
    return;
  }

  if (router.currentRoute.value.name === "ResetPassword") {
    console.log("AuthLayer - onMounted - ResetPassword");
    await authStore.getUserAction();
    console.log(
      "AuthLayer - onMounted - ResetPassword - isAuthenticated.value",
      isAuthenticated.value,
    );

    if (isAuthenticated.value) {
      await router.push({ name: "Dashboard" });
      return;
    } else {
      showView.value = true;
    }
  }
});
</script>
