import type { RouteRecordRaw } from "vue-router";

import AuthLayout from "@/layouts/AuthLayout.vue";
import AuthLayer from "@/components/pages/auth/AuthLayer.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "",
        component: AuthLayer,
        children: [
          {
            path: "signin",
            redirect: { name: "Login" },
            meta: {
              title: "Login",
              isPublic: true,
              forceShowBannerText: true,
            },
          },
          {
            path: "login",
            name: "Login",
            component: () => import("@/views/auth/LoginView.vue"),
            meta: {
              title: "Login",
              isPublic: true,
              forceShowBannerText: true,
            },
          },
          {
            path: "login-auth-aws",
            name: "LoginAuthAWS",
            component: () => import("@/views/auth/AuthenticationView.vue"),
            meta: {
              title: "AWS Login",
              isPublic: true,
              forceShowBannerText: true,
            },
          },
          {
            path: "logout",
            name: "Logout",
            component: () => import("@/views/auth/LogoutView.vue"),
            meta: {
              title: "Logout",
              isPublic: true,
              forceShowBannerText: true,
            },
          },
          {
            path: "reset-password",
            name: "ResetPassword",
            component: () => import("@/views/auth/ResetPasswordView.vue"),
            meta: {
              title: "Reset Password",
              isPublic: true,
              forceShowBannerText: true,
            },
          },
        ],
      },
    ],
  },
];

export default routes;
