import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/profile",
    component: () => import("@/layouts/DashboardLayout.vue"),
    children: [
      {
        path: "/profile",
        name: "Profile",
        component: () => import("@/views/profile/ProfileView.vue"),
        meta: {
          title: "Profile - {username}",
          bannerTitle: "Profile",
        },
        children: [
          {
            path: "change-password/:userId",
            name: "ProfileChangePassword",
            component: () => import("@/views/profile/ProfileChangePasswordView.vue"),
            meta: {
              title: "Profile Change Password",
              bannerTitle: "Profile",
            },
          },
          {
            path: "edit/:userId",
            name: "ProfileEdit",
            component: () => import("@/views/profile/ProfileEditView.vue"),
            meta: {
              title: "Profile Edit",
              bannerTitle: "Profile",
            },
          },
        ],
      },
    ],
  },
];

export default routes;
