import { Auth, Amplify, Storage } from "aws-amplify";
import { ForgotPassword, NewPasswordChallenge } from "@@/AuthTypes.ts";
import { computed, ref } from "vue";

/**
 *
 * limitation @link https://github.com/aws-amplify/amplify-flutter/issues/1902
 */
export const initAmplify = () => {
  Amplify.configure({
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: import.meta.env.VITE_APP_AUTH_IDENTITY_POOL_ID,

      // REQUIRED - Amazon Cognito Region
      region: import.meta.env.VITE_APP_AUTH_REGION,

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: import.meta.env.VITE_APP_AUTH_IDENTITY_POOL_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: import.meta.env.VITE_APP_AUTH_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: import.meta.env.VITE_APP_AUTH_CLIENT_ID,

      // OPTIONAL - Hosted UI configuration
      oauth: {
        domain: import.meta.env.VITE_APP_AUTH_DOMAIN,
        scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: `${import.meta.env.VITE_APP_AUTH_REDIRECT_SIGN_IN_URL}`,
        redirectSignOut: `${import.meta.env.VITE_APP_AUTH_REDIRECT_SIGN_OUT_URL}`,
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
    API: {
      endpoints: [
        {
          name: "APIGateway",
          endpoint: `${import.meta.env.VITE_APP_API_SCHEME}${import.meta.env.VITE_APP_API_URL}`,
        },
      ],
    },
    Storage: {
      AWSS3: {
        bucket: import.meta.env.VITE_APP_S3_BUCKET,
        // level: "private",
        region: import.meta.env.VITE_APP_AUTH_REGION,
        customPrefix: {
          public: "assets/",
        },
      },
    },
  });
  Auth.configure();
  Storage.configure();
};

export const userKeyFirst = ref(
  `CognitoIdentityServiceProvider.${import.meta.env.VITE_APP_AUTH_CLIENT_ID}`,
);
export const userKey = ref(`${userKeyFirst.value}.LastAuthUser`);
export const userDataKey = computed(
  () => `${userKeyFirst.value}.${localStorage.getItem(userKey.value)}.userData`,
);
export const getUserLocalData = () => JSON.parse(localStorage.getItem(userDataKey.value) || "{}");

export const signIn = async (email: string, password: string) => {
  return await Auth.signIn(email, password);
};
export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};
export const getUser = async () => {
  return await Auth.currentUserInfo();
};
export const getAuthenticatedUser = async () => {
  return Auth.currentAuthenticatedUser();
};
export const getAuthJWTToken = async () => {
  const session = await Auth.currentSession();

  return session.getAccessToken().getJwtToken();
};
export const refreshToken = async () => {
  return Auth.currentSession();
};
export const changePassword = async (oldPassword: string, newPassword: string) => {
  const user = await Auth.currentAuthenticatedUser();
  return await Auth.changePassword(user, oldPassword, newPassword);
};

export const checkPasswordChange = async () => {
  const currentUser = await Auth.currentAuthenticatedUser();
  // Check if the user needs to change their password
  if (currentUser.challengeName === "NEW_PASSWORD_REQUIRED") {
    console.log("User needs to change their password");
    // Redirect the user to the change password page
    // You may want to handle this according to your application flow
  } else {
    console.log("User does not need to change their password");
    // Continue with your application logic
  }
};

export const forgotPassword = async (userName: string) => {
  console.log(userName, "name");
  return await Auth.forgotPassword(userName)
    .then((response) => response)
    .catch((errr) => {
      throw errr;
    });
};
export const forgotPasswordSubmit = async (payload: ForgotPassword) => {
  return await Auth.forgotPasswordSubmit(payload.username, payload.code, payload.new_password);
};
export const completeNewPasswordChallenge = async (payload: NewPasswordChallenge) => {
  return await Auth.completeNewPassword(payload.user, payload.new_password);
};
