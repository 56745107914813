import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { useAuthStore } from "@/stores";

import LoaderController from "@/controllers/LoaderController.ts";
import { error as errorToast } from "@/services/toast.service.ts";
import { apiResponse } from "@@/CommonTypes.ts";

console.debug("Import meta -->", import.meta);

const config = {
  baseURL: `${import.meta.env.VITE_APP_API_SCHEME}${import.meta.env.VITE_APP_API_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "X-CSRFToken": getCookies("csrftoken"),
    // "X-REQUESTED-WITH": "XMLHttpRequest",
    // Authorization: `Bearer ${auth0.getAccessTokenSilently()}`,
  },
};

const useAxios = (): AxiosInstance => {
  // const siteStateStore = useSiteStateStore();
  // return siteStateStore.apiCallable ? axiosInstance : (Axios as unknown as AxiosInstance);
  return axiosInstance;
};

const axiosInstance = axios.create(config);

const onRequest = async <T extends InternalAxiosRequestConfig>(config: T): Promise<T> => {
  if (config.headers?.showLoader ?? true) {
    LoaderController.showLoader();
  }

  const authStore = useAuthStore();

  // await auth0.getAccessTokenSilently().then((token) => {
  //   config.headers["Authorization"] = `Bearer ${token}`;
  // });

  await authStore.getAuthJWTTokenAction().then((token) => {
    config.headers["Authorization"] = `Bearer ${token}`;
  });

  return config;
};

const onRequestError = () => (error: never) => {
  LoaderController.hideLoader();

  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(onRequest, onRequestError);

const onResponseError = <T extends AxiosError>(error: T): Promise<T> => {
  LoaderController.hideLoader();

  const errorData = error.response?.data as apiResponse<unknown>;

  console.log("onResponseError ===========>");
  console.error("error: ", error);

  const defaultMessages: string = "Contact support";
  const message: string = errorData?.message
    ? // ? errorData.message.split(": ").at(-1) ?? defaultMessages
      errorData.message ?? defaultMessages
    : error.message ?? defaultMessages;

  errorToast("Something went wrong!", message, 5000);

  return Promise.reject(error);
};

const onResponse = <T extends AxiosResponse>(response: T): T => {
  if (response.config.headers?.showLoader ?? true) {
    LoaderController.hideLoader();
  }

  return response;
};

axiosInstance.interceptors.response.use(onResponse, onResponseError);

export { useAxios };
