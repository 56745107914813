import type { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("@/layouts/DashboardLayout.vue"),
    children: [
      {
        path: "survey",
        name: "SurveyList",
        component: () => import("@/views/survey/SurveyListView.vue"),
        meta: {
          title: "Survey list",
        },
        children: [
          {
            path: ":surveyId",
            name: "SurveyDetails",
            component: () => import("@/views/survey/SurveyDetailsView.vue"),
            props: true,
            meta: {
              title: "Survey details",
              bannerTitle: "Survey list",
            },
          },
        ],
      },
    ],
  },
];

export default routes;
