import { ConfirmationStatus, UserRoles } from "@@/stores/modules/AuthTypes.ts";

export type User = {
  phone_number: string;
  name?: string;
  full_name?: string;
  role: UserRoles;
  email: string;
  confirmation_status?: ConfirmationStatus;
  avatar?: string;
  id?: string;
  update_time?: string;
};

export type CreateUser = User & {
  confirmPassword: string;
};

export type Survey = {
  created_at: string;
  form_uid: string;
  categories: Array<SurveyCategoryType>;
  survey_id: string;
  surveyor_id: string;
  photo?: string;
  shop_name?: string;
  surveyor_role: UserRoles.Surveyor | UserRoles.Sales_Representative;
};

export type SurveyQuestionsOnlyKeyStringType = Omit<Survey, "categories"> & {
  categories: Array<SurveyCategoryQuestionsKeyStringType>;
};

export type SurveyKeyStringType = Omit<Survey, "categories"> & {
  categories: Record<string | number, SurveyCategoryQuestionsKeyStringType>;
};

export type SurveyCategoryQuestionsKeyStringType = {
  id: string | number;
  name: string;
  questions: SurveyQuestionsKeyStringType;
};

export type SurveyQuestionsKeyStringType = Record<string | number, SurveySingleQuestionWithSubType>;

export type SurveySingleQuestionWithSubType = SurveySingleQuestionType & {
  subquestions?: SurveySubQuestionsKeyStringType;
};

export type SurveySubQuestionsKeyStringType = Record<string | number, SurveySingleQuestionType>;

export type LocationType = {
  lat: number;
  lng: number;
};

export type SurveyCategoryType = {
  id: string | number;
  name: string;
  questions: Array<SurveyQuestionType>;
};

export type SurveyQuestionType = {
  id: string | number;
  options?: Array<SurveyQuestionOptionsType>;
  default?: number;
  limit?: number;
  required?: boolean;
  title: string;
  // question?: string;
  type: SurveyQuestionTypeType;
  subquestions?: Array<SurveySingleQuestionType>;
  answer?: string | number | boolean | Array<string | number | boolean>;
};

export type SurveySingleQuestionType = Omit<SurveyQuestionType, "subquestions">;

export type SurveyQuestionOptionsType = {
  id: string | number;
  name: string;
};

export enum SurveyCategoriesEnum {
  "Surveyor Details" = "Surveyor Details",
  "Store Details" = "Store Details",
  "Supply Details" = "Supply Details",
  "Customer Relations" = "Customer Relations",
  "Competitor Details" = "Competitor Details",
}

export type SurveyQuestionTypeType =
  | "text"
  | "email"
  | "date"
  | "time"
  | "datetime"
  | "radio"
  | "photo"
  | "phone"
  | "list"
  | "checkbox"
  | "number"
  | "multitext"
  | "addrow"
  | "addgrouprow"
  | "location";

export type apiResponse<T> = {
  data: T;
  status: number;
  code: number;
  message: string;
};

export type SurveyListRequestType = {
  search?: string | null;
  start_date?: string;
  end_date?: string;
  surveyor_id?: string | null;
  role?: UserRoles;
};

export type Nullable<T> = T | null;

export type DebounceType = {
  after?: number;
  search?: string;
  callback: { (argument: { search: string }) };
};

export enum S3ImageBases {
  "ASSETS" = "/assets",
  "THUMBNAILS" = "/thumbnails",
  "TEMP" = "/temp",
}
